/**
 * 할인율을 적용하여 할인 후 가격을 계산합니다.
 * @param {number} price - 원래 가격
 * @param {number} discountRate - 할인율 (%)
 * @returns {number} 할인 후 가격
 */
export const calculateDiscountedPrice = (price, discountRate) => {
  if (!price || !discountRate) return price;
  return Math.round(price * (1 - discountRate / 100));
};

/**
 * 숫자를 통화 형식으로 포맷팅합니다 (1000단위 콤마 추가).
 * @param {number} price - 포맷팅할 가격
 * @returns {string} 포맷팅된 가격 문자열
 */
export const formatCurrency = (price) => {
  if (!price && price !== 0) return "";
  return '₩' + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 * 가격 정보를 계산하고 포맷팅합니다.
 * @param {Object} priceData - 가격 데이터 객체
 * @returns {Object} 계산 및 포맷팅된 가격 정보
 */
export const processPriceInfo = (priceData) => {
  if (!priceData) return null;
  
  const processSubscription = (subscription) => {
    if (!subscription) return null;
    
    const { price, discount_rate } = subscription;
    const priceAfterDiscount = calculateDiscountedPrice(price, discount_rate);
    const wonPrice = formatCurrency(price);
    
    return {
      price,
      discountRate: discount_rate,
      priceAfterDiscount,
      wonPrice
    };
  };
  
  return {
    monthly: processSubscription(priceData.monthly),
    fourMonth: processSubscription(priceData.fourMonth)
  };
};
