import React, { useCallback, useEffect, useState, useRef } from "react";
import {
  StyledLectureDetailContainer,
  StyledRelatedProgram,
  LectureDescContainer,
  LectureParagraph,
  LectureList,
  LectureImportantNote,
} from "./LectureDetail.styles";
import { useNavigate, useParams } from "react-router-dom";

import * as lectureAPI from "../../apis/lecture";
import * as subscriptionAPI from "../../apis/subscription";
import { processPriceInfo, formatCurrency } from "../../utils/common";

import { useTranslation } from "react-i18next";
import isLangKo from "../../utils/isLangKo";
import NavBar from "../common/NavBar";

import CheckCircle from "../../icon/check-circle.svg";
import ArrowRight from "../../icon/arrow-right-short.svg";
import Suitcase from "../../icon/suitcase-three.svg";
import { Container } from "react-bootstrap";
import Footer from "../common/Footer";

const RelatedProgram = ({
  id,
  name,
  descriptionSummary,
  descriptionSummaryEnglish,
  difficulty,
}) => {
  const navigate = useNavigate();

  return (
    <StyledRelatedProgram
      className="related-program"
      onClick={() => {
        navigate(`/programs/lecture/${id}`);
      }}
    >
      <div className="title">{name}</div>
      <div className="desc">Nanodegree program</div>
      <div className="summary">{isLangKo() ? descriptionSummary : descriptionSummaryEnglish}</div>
      <div className="difficulty-container">
        <div className="difficulty">{difficulty}</div>
      </div>
    </StyledRelatedProgram>
  );
};

export default function LectureDetail() {
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [lecture, setLecture] = useState([]);

  const [priceInfo, setPriceInfo] = useState({
    monthly: {
      price: 0,
      discountRate: 0,
      priceAfterDiscount: 0,
      wonPrice: ""
    },
    fourMonth: {
      price: 0,
      discountRate: 0,
      priceAfterDiscount: 0,
      wonPrice: ""
    }
  });

  const getStartedRef = useRef(null);

  useEffect(() => {
    lectureAPI.getLecture({ id: params.id }).then((res) => {
      if (res.data.linusCode === 1) {
        setLecture(res.data.data);
        setLoading(false);
      }
    });

    subscriptionAPI.getSubscriptionPrices().then((res) => {
      setPriceInfo(processPriceInfo(res));
    });
  }, [params.id]);

  const scrollToGetStarted = useCallback(() => {
    if (getStartedRef.current) {
      getStartedRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [getStartedRef]);

  const onClickBuyNow = useCallback(() => {
    scrollToGetStarted();
  }, [scrollToGetStarted]);

  const onClickEnrollNow = useCallback(
    (plan = "monthly") => {
      if (!localStorage.getItem("accessToken")) {
        if (window.confirm("로그인 후 이용해주세요.")) {
          navigate("/auth/sign-in");
          return;
        } else {
          // 사용자가 "취소"를 눌렀을 때 아무 동작도 하지 않도록 처리
          return;
        }
      }

      navigate("/order", { state: { subplan: plan } });
    },
    [navigate],
  );

  return (
    <>
      <NavBar navColor={"black"} />
      {!loading && (
        <>
          <StyledLectureDetailContainer>
            <div className="summary-container">
              <div className="thumbnail">
                <img src={lecture?.thumbnail} alt="thumbnail" loading="eager" />
              </div>
              <div className="summary-text-container">
                <div className="title">{lecture?.name}</div>
                <div className="info">
                  <div className="info-item period">
                    <span>{t("expect-time")}</span>
                    {isLangKo() ? lecture?.estimatedPeriod : lecture?.estimatedPeriodEnglish}{" "}
                    {isLangKo()
                      ? lecture?.estimatedPeriodCondition
                      : lecture?.estimatedPeriodConditionEnglish}
                  </div>
                  <div className="info-item difficulty">
                    <span>{t("lecture-difficulty")}</span> {lecture?.difficulty}
                  </div>
                </div>
                <div className="desc">
                  {isLangKo() ? lecture?.descriptionSummary : lecture?.descriptionSummaryEnglish}
                </div>
                <div className="buttons">
                  <button
                    className="order"
                    onClick={(e) => {
                      e.preventDefault();
                      onClickBuyNow();
                    }}
                  >
                    <div>{t("enroll-now")}</div>
                    <img src="/img/arrow-more-black.svg" alt="arrow-more" />
                  </button>
                  <a
                    href={lecture.syllabus_download_url}
                    className="order download"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div>{t("download-syllabi")}</div>
                    <img src="/img/arrow-more-download.svg" alt="arrow-more" />
                  </a>
                </div>
              </div>
            </div>
            <LectureDesc lecture={lecture} t={t} />
            <div ref={getStartedRef} className="price-container">
              <div className="title">
                <div className="lecture-name">{lecture?.name}</div>
                <div className="sub">{t("lecture-title-sub")}</div>
              </div>
              <div className="contents" style={{ gap: "10px" }}>
                <div className="us-container one-monthly-sub">
                  <div className="title">
                    <div className="desc">
                      <div>{t("one-monthly-sub")}</div>
                      {/* 만약 할인 정보가 있으면 할인 정보를 표시 */}
                      {priceInfo.monthly.discountRate > 0 && (
                        <span className="discount">Save {priceInfo.monthly.discountRate}%</span>
                      )}
                    </div>
                  </div>
                  <div className="price">
                    {/* 할인 정보가 있으면 할인 정보를 표시 */}
                    {priceInfo.monthly.discountRate > 0 && (
                      <del
                        style={{
                          color: "#696969",
                          fontWeight: "300",
                          fontSize: "2.8rem",
                          marginBottom: "10px",
                        }}
                      >
                        {priceInfo.monthly.wonPrice}
                      </del>
                    )}
                    <div>{priceInfo.monthly.discountRate > 0 
                      ? formatCurrency(priceInfo.monthly.priceAfterDiscount) 
                      : priceInfo.monthly.wonPrice}</div>
                  </div>
                  <hr className="divider" />
                  <ul className="benefits-list">
                    <li style={{ fontWeight: "bold" }}>
                      <img src={CheckCircle} alt="Check Circle" />
                      {t("benefits-list1")}
                    </li>
                    <li>
                      <img src={CheckCircle} alt="Check Circle" />
                      {t("benefits-list2")}
                    </li>
                    <li>
                      <img src={CheckCircle} alt="Check Circle" />
                      {t("benefits-list3")}
                    </li>
                    <li>
                      <img src={CheckCircle} alt="Check Circle" />
                      {t("benefits-list4")}
                    </li>
                    <li>
                      <img src={CheckCircle} alt="Check Circle" />
                      {t("benefits-list5")}
                    </li>
                  </ul>
                  <div className="button-container">
                    <button
                      onClick={() => {
                        onClickEnrollNow("monthly");
                      }}
                    >
                      {t("Enroll Now")}
                      <img
                        src={ArrowRight}
                        alt="Arrow Right"
                        style={{ filter: "brightness(0) invert(1)" }}
                      />
                    </button>
                  </div>
                </div>
                <div className="us-container four-monthly-sub">
                  <div className="title">
                    <div className="desc">
                      <div>{t("four-monthly-sub")}</div>
                      {priceInfo.fourMonth.discountRate > 0 && (
                        <span className="discount">Save {priceInfo.fourMonth.discountRate}%</span>
                      )}
                    </div>
                    <div className="sub">{t("four-monthly-sub-time")}</div>
                  </div>
                  <div className="price">
                    {priceInfo.fourMonth.discountRate > 0 && (
                      <del
                        style={{
                          color: "#696969",
                          fontWeight: "300",
                          fontSize: "2.8rem",
                          marginBottom: "10px",
                        }}
                      >
                        {priceInfo.fourMonth.wonPrice}
                      </del>
                    )}
                    <div>{priceInfo.fourMonth.discountRate > 0 
                      ? formatCurrency(priceInfo.fourMonth.priceAfterDiscount) 
                      : priceInfo.fourMonth.wonPrice}</div>
                  </div>
                  <hr className="divider" />
                  <ul className="benefits-list">
                    <li>
                      <img src={CheckCircle} alt="Check Circle" />
                      {t("four-monthly-sub-benefit1")}
                    </li>
                    <li>
                      <img src={CheckCircle} alt="Check Circle" />
                      {t("four-monthly-sub-benefit2")}
                    </li>
                    <li>
                      <img src={CheckCircle} alt="Check Circle" />
                      {t("four-monthly-sub-benefit3")}
                    </li>
                  </ul>
                  {/* <div className="note">
                  <small>{t("four-monthly-sub-note")}</small>
                </div> */}
                  <div className="button-container">
                    <button
                      onClick={() => {
                        onClickEnrollNow("fourMonth");
                      }}
                    >
                      {t("Enroll Now")}
                      <img
                        src={ArrowRight}
                        alt="Arrow Right"
                        style={{ filter: "brightness(0) invert(1)" }}
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div className="contents-bottom">
                <div className="business-container">
                  <div className="business-container-sub">
                    <img
                      alt=""
                      loading="lazy"
                      width="40"
                      height="40"
                      decoding="async"
                      data-nimg="1"
                      src={Suitcase}
                      style={{ color: "transparent" }}
                    />
                    <div className="desc">
                      <h3>Udacity for Business</h3>
                      <p>Buying for a business? Check out our business plans.</p>
                    </div>
                  </div>
                  <a className="learn-more-button" href="/contact">
                    Learn More
                    <span>
                      <img src="/img/arrow-more.svg" alt="Arrow Right" />
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="related-container">
              <div className="title">Related Programs</div>
              <div className="contents">
                {lecture?.relatedLectures?.map((i, index) => (
                  <RelatedProgram key={index} {...i} />
                ))}
              </div>
            </div>
          </StyledLectureDetailContainer>
          <Footer />
        </>
      )}
    </>
  );
}

const LectureDesc = ({ lecture, t }) => {
  return (
    <LectureDescContainer fluid>
      <Container>
        <LectureParagraph>
          <div className="fs-4">
            <strong>
              {isLangKo()
                ? `선택하신 과정은 ${lecture?.name} 입니다.`
                : `This lecture name is ${lecture?.name}`}
            </strong>
          </div>
        </LectureParagraph>
        <LectureList>
          <li>
            <strong>Step 1. </strong>
            {t("LectureDetail_Desc_Step1")}
          </li>
          <li>
            <strong>Step 2. </strong>
            {t("LectureDetail_Desc_Step2")}
          </li>
          <div className="mb-2" style={{ paddingLeft: "20px" }}>
            {t("LectureDetail_Desc_Sub1")} puroom@gmail.com
          </div>
          <img
            alt="udacity id"
            loading="lazy"
            src="/img/lecture/lectureDetail_img2.png"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
          <div className="mb-2" style={{ paddingLeft: "20px" }}>
            {t("LectureDetail_Desc_Sub2")}Self Driving Car Engineer
          </div>
          <img
            alt="udacity lecture name"
            loading="lazy"
            src="/img/lecture/lectureDetail_img.png"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
          <li>
            <strong>Step 3. </strong>
            {t("LectureDetail_Desc_Step3")}
          </li>
          <li>
            <strong>Step 4. </strong>
            {t("LectureDetail_Desc_Step4")}
          </li>
        </LectureList>
        <LectureImportantNote>
          <LectureParagraph>
            ► UDACITY Koreapartner에서 제공하는 상품의 취소/환불 안내
          </LectureParagraph>
          <LectureList>
            <li>결제일로부터 7일 이내: 전액 환불 (결제금액 100% 환불 가능)</li>
            <li>결제일로부터 8~15일 이내: 부분 환불 (결제금액 50% 환불 가능)</li>
            <li>결제 후 16일 경과 시 환불 불가</li>
            <li>
              환불 시 UDACITY Koreapartner에서 제공한 모든 자료는 회수되며 회수비용은 소비자
              부담입니다.
            </li>
            <li>취소 및 환불과 관련하여서는 1899-6629로 문의해 주시기 바랍니다.</li>
          </LectureList>
        </LectureImportantNote>
      </Container>
    </LectureDescContainer>
  );
};
