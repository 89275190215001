import client from "./client";

const handleError = (error) => {
  console.log("API call failed:", error);
  throw error;
}

// 구독 가격 정보 가져오기
export const getSubscriptionPrices = async () => {
  try {
    const response = await client.get('/order/subscriptions/prices/');
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

// 구독 정보 가져오기
export const getSubscriptions = async () => {
  try {
    const response = await client.get('/order/subscriptions/');
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

// 특정 구독 정보 가져오기
export const getSubscription = async (id) => {
  try {
    const response = await client.get(`/order/subscriptions/${id}/`);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

// 쿠폰 유효성 검증
export const validateCoupon = async (code, orderAmount = null, subscriptionType = '1M') => {
  try {
    const response = await client.post('/coupon/coupons/validate_coupon/', {
      code,
      order_amount: orderAmount,
      subscription_type: subscriptionType
    });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

// 쿠폰 적용하기
export const applyCoupon = async (code, orderAmount = null, orderId = null, subscriptionType = '1M') => {
  try {
    const response = await client.post('/coupon/coupons/apply_coupon/', {
      code,
      order_amount: orderAmount,
      order_id: orderId,
      subscription_type: subscriptionType
    });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

// 사용자의 쿠폰 사용 내역 조회
export const getCouponUsages = async () => {
  try {
    const response = await client.get('/coupon/coupon-usages/');
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};


