import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Accordion, Button, Form, InputGroup } from "react-bootstrap";
import { StyledOrderPageContainer } from "./OrderPage.styles";
import Payment from "../components/common/Payment";
import { useInput } from "../utils/useInput";
import { useTranslation } from "react-i18next";
import isLangKo from "../utils/isLangKo";
import NavBar from "../components/common/NavBar";
import { getSubscriptionPrices, validateCoupon } from "../apis/subscription";
import { processPriceInfo, formatCurrency } from "../utils/common";

import CheckCircle from "../icon/check-circle.svg";

export default function OrderPage({ subplan }) {
  const { t } = useTranslation();
  const location = useLocation();
  const plan = location.state?.subplan || "monthly";

  const [name, setName] = useInput("");
  const [phone, setPhone] = useInput("");
  const [email, setEmail] = useInput("");
  const [msg, setMsg] = useInput("");
  const [modalVisible, setModalVisible] = useState(false);

  const [subscriptionPlan, setSubscriptionPlan] = useState(plan);
  const [priceInfo, setPriceInfo] = useState({
    monthly: {
      price: 320000,
      discountRate: 0,
      priceAfterDiscount: 320000,
      wonPrice: "₩320,000"
    },
    fourMonth: {
      price: 1280000,
      discountRate: 0,
      priceAfterDiscount: 1280000,
      wonPrice: "₩1,280,000"
    }
  });
  const [amount, setAmount] = useState(0);
  const [bill, setBill] = useState("");
  
  // Add states for coupon functionality
  const [couponCode, setCouponCode] = useState("");
  const [couponApplied, setCouponApplied] = useState(false);
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [couponError, setCouponError] = useState("");
  const [isValidatingCoupon, setIsValidatingCoupon] = useState(false);

  useEffect(() => {
    const fetchSubscriptionPrices = async () => {
      try {
        const response = await getSubscriptionPrices();
        const processedPriceInfo = processPriceInfo(response);
        setPriceInfo(processedPriceInfo);
        
        // Set initial amount and bill based on the selected plan
        const initialAmount = plan === "fourMonth" 
          ? processedPriceInfo.fourMonth.priceAfterDiscount 
          : processedPriceInfo.monthly.priceAfterDiscount;
        
        setAmount(initialAmount);
        setBill(formatCurrency(initialAmount));
      } catch (error) {
        console.error("Error fetching subscription prices:", error);
      }
    };
    
    fetchSubscriptionPrices();
  }, [plan]);

  useEffect(() => {
    if (priceInfo) {
      const initialAmount = plan === "fourMonth" 
        ? priceInfo.fourMonth.priceAfterDiscount 
        : priceInfo.monthly.priceAfterDiscount;
      
      setSubscriptionPlan(plan);
      setAmount(initialAmount);
      setBill(formatCurrency(initialAmount));
    }
  }, [plan, priceInfo]);

  const handlePlanChange = (selectedPlan) => {
    if (!priceInfo) return;
    
    // Reset coupon when plan changes
    setCouponApplied(false);
    setCouponDiscount(0);
    setCouponError("");
    
    const newAmount = selectedPlan === "fourMonth" 
      ? priceInfo.fourMonth.priceAfterDiscount 
      : priceInfo.monthly.priceAfterDiscount;
    
    setSubscriptionPlan(selectedPlan);
    setAmount(newAmount);
    setBill(formatCurrency(newAmount));
  };

  // Add coupon validation function
  const checkCoupon = async () => {
    if (!couponCode.trim()) {
      setCouponError("쿠폰 코드를 입력해주세요.");
      return;
    }

    setIsValidatingCoupon(true);
    setCouponError("");

    try {
      const subscriptionType = subscriptionPlan === "monthly" ? "1M" : "4M";
      const response = await validateCoupon(couponCode, amount, subscriptionType);
      
      if (response.coupon.is_valid) {
        applyCouponDiscount(response.coupon);
      } else {
        // Coupon is invalid
        setCouponError(response.message || "유효하지 않은 쿠폰입니다.");
        setCouponApplied(false);
        setCouponDiscount(0);
      }
    } catch (error) {
      console.error("Error validating coupon:", error);
      setCouponError("쿠폰이 유효하지 않습니다");
      setCouponApplied(false);
      setCouponDiscount(0);
    } finally {
      setIsValidatingCoupon(false);
    }
  };

  const applyCouponDiscount = (couponData) => {
    if (!couponData) return;


    console.log("couponData", couponData);
    let discountedAmount = amount;

    if (couponData.coupon_type === "PERCENTAGE") {
      const discountAmount = Math.round(amount * (couponData.discount_value / 100));
      setCouponDiscount(discountAmount);
      discountedAmount = amount - discountAmount;
    } else if (couponData.coupon_type === "FIXED") {
      const discountAmount = couponData.discount_value;
      setCouponDiscount(discountAmount);
      discountedAmount = amount - discountAmount;
    }

    setAmount(discountedAmount);
    setBill(formatCurrency(discountedAmount));
    setCouponApplied(true);
  };

  // Function to handle coupon code input change
  const handleCouponCodeChange = (e) => {
    setCouponCode(e.target.value);
    // Reset error when user types
    if (couponError) setCouponError("");
  };

  return (
    <>
      <NavBar navColor={"black"} />
      <StyledOrderPageContainer>
        {modalVisible && (
          <div
            className="modal"
            onClick={(e) => {
              e.preventDefault();
              if (
                e.target.className === "contents-container" ||
                e.target.className === "contents" ||
                e.target.className === "title" ||
                e.target.className === "title-text" ||
                e.target.className === "close-container"
              ) {
                return;
              }

              setModalVisible(false);
              document.body.style.overflow = "unset";
            }}
          >
            <div className="contents-container">
              <div className="title">
                <div className="close-container">
                  <img src="/img/close-black.svg" alt="close" />
                </div>
              </div>
              <div className="contents">
                결제가 완료되었습니다. <br />
                잠시만 기다려 주시면 결제하신 강의를 <br />
                <span
                  onClick={() => {
                    window.open("https://www.udacity.com");
                  }}
                >
                  UDACITY 홈페이지
                </span>
                에서 수강 할 수 있습니다.
              </div>
            </div>
          </div>
        )}
        <div className="left-container">
          <div className="form-container">
            <div className="title-container">
              <h3>{t("order-pay")}</h3>
            </div>

            <div className="orderer-info-container">
              <div className="orderer-info-title">선택하신 대표과정</div>
              <div className="text-info">* 선택이외의 과정이 필요하시면 메일을 보내주세요</div>
              <div className="orderer-info-title">{t("orderer-info")}</div>
              <div className="orderer-form">
                <div className="orderer-form-row">
                  <div className="orderer-form-input">
                    <label htmlFor="name">
                      이름 Full Name <span>*</span>
                    </label>
                    <input
                      name="name"
                      id="name"
                      placeholder={t("order-placeholder", {
                        input: isLangKo() ? "이름" : "your name",
                      })}
                      type={"text"}
                      onChange={setName}
                    />
                  </div>
                  <div className="orderer-form-input">
                    <label htmlFor="phone">
                      연락처 Phone <span>*</span>
                    </label>
                    <input
                      name="phone"
                      id="phone"
                      placeholder={t("order-placeholder", {
                        input: isLangKo() ? "연락처" : "your phone number",
                      })}
                      type={"phone"}
                      onChange={setPhone}
                    />
                  </div>
                </div>
                <div className="orderer-form-row">
                  <div className="orderer-form-input">
                    <label htmlFor="email">
                      이메일 Email <span>*</span>
                    </label>
                    <input
                      name="email"
                      id="email"
                      placeholder={t("order-placeholder", {
                        input: isLangKo() ? "이메일" : "your email address",
                      })}
                      type={"email"}
                      onChange={setEmail}
                    />
                  </div>
                </div>
                <div className="email-desc-container">
                  <p className="red-text text">* {t("write-udacity-email")}</p>
                  <p className="text">{t("udacity-email-desc")}</p>
                </div>
              </div>
            </div>
            <div className="additional-info-container">
              <div className="additional-info-title">{t("add-info")}</div>
              <div className="additional-info-form">
                <label htmlFor="additional">
                  주문 관련 메시지 Additional Comments <span>(선택)</span>
                </label>
                <textarea
                  name="additional"
                  placeholder={t("order-placeholder", {
                    input: isLangKo() ? "주문 관련 메시지" : "additional comments",
                  })}
                  onChange={setMsg}
                />
              </div>
            </div>
            <div className="button-container">
              <Payment
                orderMessage={msg}
                amount={amount}
                udacityEmail={email}
                name={name}
                phone={phone}
                btnText={t("payment")}
                onComplete={() => {
                  setModalVisible(true);
                  setTimeout(() => {
                    setModalVisible(false);
                  }, 5000);
                }}
              />
            </div>
          </div>
        </div>
        <div className="right-container">
          <div className="lecture-container">
            <div className="lecture-title">Individual Plan</div>
            <div className="plan-details">
              <p>{t("order-pay-will")}</p>
              <p>{bill}</p>
            </div>
            <div className="subscription-options">
              <div className="radio-group">
                <label className="radio-option">
                  <input
                    type="radio"
                    id="fourMonth"
                    name="subscriptionPlan"
                    value="fourMonth"
                    checked={subscriptionPlan === "fourMonth"}
                    onChange={() => handlePlanChange("fourMonth")}
                  />
                  {t("subscription-4month")} 
                  {priceInfo.fourMonth.discountRate > 0 && (
                    <span style={{ color: "#198754", paddingLeft: "5px" }}>Save {priceInfo.fourMonth.discountRate}%</span>
                  )}
                </label>
                <label className="radio-option">
                  <input
                    type="radio"
                    id="monthly"
                    name="subscriptionPlan"
                    value="monthly"
                    checked={subscriptionPlan === "monthly"}
                    onChange={() => handlePlanChange("monthly")}
                  />
                  {t("subscription-month")} 
                  {priceInfo.monthly.discountRate > 0 && (
                    <span style={{ color: "#198754", paddingLeft: "5px" }}>Save {priceInfo.monthly.discountRate}%</span>
                  )}
                </label>
              </div>
            </div>
            <div className="discount-code-section">
              <Accordion defaultActiveKey="0">
                <Accordion.Item>
                  <Accordion.Header>할인쿠폰입력</Accordion.Header>
                  <Accordion.Body className="d-flex flex-column">
                    <div className="d-flex align-items-stretch mb-2">
                      <div className="flex-grow-1 me-2">
                        <InputGroup>
                          <Form.Control
                            placeholder="Gift card or discount coupon"
                            aria-label="Discount code"
                            value={couponCode}
                            onChange={handleCouponCodeChange}
                            isInvalid={!!couponError}
                            disabled={couponApplied || isValidatingCoupon}
                          />
                          {couponError && (
                            <Form.Control.Feedback type="invalid">
                              {couponError}
                            </Form.Control.Feedback>
                          )}
                        </InputGroup>
                      </div>
                      <div style={{ width: "40%" }}>
                        {!couponApplied ? (
                          <Button 
                            variant="outline-primary" 
                            className="w-100"
                            onClick={checkCoupon}
                            disabled={isValidatingCoupon || !couponCode.trim()}
                          >
                            {isValidatingCoupon ? "확인 중..." : "Apply"}
                          </Button>
                        ) : (
                          <Button 
                            variant="outline-danger" 
                            className="w-100"
                            onClick={() => {
                              setCouponApplied(false);
                              setCouponDiscount(0);
                              setCouponCode("");
                              
                              // Reset to original amount based on subscription plan
                              const originalAmount = subscriptionPlan === "fourMonth" 
                                ? priceInfo.fourMonth.priceAfterDiscount 
                                : priceInfo.monthly.priceAfterDiscount;
                              
                              setAmount(originalAmount);
                              setBill(formatCurrency(originalAmount));
                            }}
                          >
                            Remove
                          </Button>
                        )}
                      </div>
                    </div>
                    {couponApplied && (
                      <div className="coupon-applied-info text-success">
                        쿠폰이 적용되었습니다. 할인금액: {formatCurrency(couponDiscount)}
                      </div>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            {/* Total section */}
            <div className="total-section">
              <p>Total</p>
              <div>{bill}</div>
              {couponApplied && (
                <div className="mt-1">
                  <small className="text-muted">
                    <del>
                      {formatCurrency(subscriptionPlan === "fourMonth" 
                        ? priceInfo.fourMonth.priceAfterDiscount 
                        : priceInfo.monthly.priceAfterDiscount)}
                    </del>
                    {" "}
                    <span className="text-success">
                      ({formatCurrency(couponDiscount)} 할인 적용)
                    </span>
                  </small>
                </div>
              )}
            </div>
          </div>
          <aside className="udacity-business-container">
            <div className="title">{t("plan-include")}</div>
            <ul className="plan-include">
              <li style={{ fontWeight: "bold" }}>
                <img src={CheckCircle} alt="Check Circle" />
                {t("plan-include1")}
              </li>
              <li>
                <img src={CheckCircle} alt="Check Circle" />
                {t("plan-include2")}
              </li>
              <li>
                <img src={CheckCircle} alt="Check Circle" />
                {t("plan-include3")}
              </li>
              <li>
                <img src={CheckCircle} alt="Check Circle" />
                {t("plan-include4")}
              </li>
            </ul>
          </aside>
        </div>
      </StyledOrderPageContainer>
    </>
  );
}
